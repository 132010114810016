html {
    height: 100%;
    overflow: hidden;
  }
  
  body {
    margin: 0; 
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
.col-lg-6 {
    padding: 2rem;
}
h1 {
    color: black;
    font-weight: 700;
    font-size: 6rem;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgb(213, 135, 79) 50%, rgb(255, 166, 102) 100%);
    padding: 0 1rem 0.5rem 1rem;
    /* width: 46%; */
    margin-bottom: 3rem;
}
h2 {
    font-weight: 700;
    font-size: 2rem;
    margin-top: 1rem;
    border-bottom: 1px solid black;
    padding: 1rem 0 0.5rem 0;
}
p {
    color: rgb(68, 68, 68);
    font-weight: 200;
    font-size: 1.5rem;
}
img {
    max-width: 100%;
    transform: scale(1);
    transition: transform 0.3s;
}
img:hover {
    transform: scale(1.1);
    transition: transform 0.3s;
}
.navbar-toggler {
    width: 2.7rem;
}

@media screen and (max-width: 425px) {
    h1 {
        padding: 0 0 0 1rem;
        font-size: 4rem;
    }
    p {
        margin-top: 1rem;
    }
    .navbar-brand {
        margin-left: 1rem !important;
    }
}